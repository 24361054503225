<template>

    <div class="articlePage">
        <div class="content">
            <V-ArticleList :newarticlelist="list"></V-ArticleList>
        </div>
         <br/>
        <div class="page-nav">
            <el-pagination
                background
                layout="prev, pager, next"
                :hide-on-single-page="hidePageNav"
                :total="totlePage"
                :page-size="pageSize"
                @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import ArticleList from '@/components/one-article/type-one/index.vue'
import {DELETE,QUERY}  from '@/services/dao.js'
export default {
    data(){
        return{
            searchWord:"",
            hidePageNav:true,
            pageNum:0,
            pageSize:12,
            totlePage:0,
            orderActive:1,
            list:[]
        }
    },
    created: function(){
        this.init();
    },
    watch: {
        '$route' (to, from) { //监听路由是否变化
            if(to.query.searchWord != from.query.searchWord){
                this.init();//重新加载数据
            }
        }
    },
    methods:{        //初始化
        init(){
             this.searchWord=this.$route.query.searchWord;
             this.searchInfo(this.pageSize,0);
        },
        //前一页
        prevPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //下一页
        nextPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //当前页
        currentPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
      async  searchInfo(limit,offest){
            let info=  await  QUERY("post","",'  HeadlineRecommendArticle(where: {Article: {keyword: {_like: "%'+this.searchWord+'%"}}}, limit: '+limit+', offset: '+offest+') {    time    Article {      accessory      authorId      authorName      browseNum      collectNum      commentNum      concernNum      content      createTime      hotNum      id      img      intro      keyword      praiseNum      reference      theme_id      title      type    }    articleId    id    reason    referrer  }  HeadlineRecommendArticle_aggregate(where: {Article: {keyword: {_like: "%'+this.searchWord+'%"}}}) {    aggregate {      count    }  }');
            this.list.splice(0,this.list.length);
            for(let i=0;i<info.data.HeadlineRecommendArticle.length;i++){
                this.list.push({
                    title:info.data.HeadlineRecommendArticle[i].Article.title,
                    intro:info.data.HeadlineRecommendArticle[i].Article.intro,
                    name:info.data.HeadlineRecommendArticle[i].Article.authorName,
                    time:info.data.HeadlineRecommendArticle[i].Article.createTime,
                    collectNum:info.data.HeadlineRecommendArticle[i].Article.collectNum,
                    userId:2,
                    commentNum:info.data.HeadlineRecommendArticle[i].Article.commentNum,
                    image:info.data.HeadlineRecommendArticle[i].Article.img,
                    id:info.data.HeadlineRecommendArticle[i].Article.id,
                    hotNum:info.data.HeadlineRecommendArticle[i].Article.hotNum,
                    praiseNum:info.data.HeadlineRecommendArticle[i].Article.praiseNum,
                    concernNum:info.data.HeadlineRecommendArticle[i].Article.concernNum,
                    keyword:info.data.HeadlineRecommendArticle[i].Article.keyword
                });
            }
            this.totlePage=info.data.Article_aggregate.aggregate.count;
        }
    },
    components:{
        'V-ArticleList':ArticleList,
    }
}
</script>

<style scoped>
    @import "~@/styles/page/index.css";
    .search-container>.main>div>div{
        display: inline-block;
        width:800px;
    }
</style>